//https://stackoverflow.com/a/43208163

export function getDistance(from, to) {
  // return distance in meters
  var lon1 = toRadian(from.lng),
    lat1 = toRadian(from.lat),
    lon2 = toRadian(to.lng),
    lat2 = toRadian(to.lat);

  var deltaLat = lat2 - lat1;
  var deltaLon = lon2 - lon1;

  var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
  var c = 2 * Math.asin(Math.sqrt(a));
  var EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS * 1000;
}
function toRadian(degree) {
  return degree*Math.PI/180;
}
