import { Icon } from "leaflet";
import { useEffect, useState } from "react";

const iconBaseProps = {
  iconSize: [36, 36],
  iconAnchor: [18, 18],
  shadowSize: [36, 36],
  shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png",
  shadowAnchor: [10, 18],
  popupAnchor: [0, -16],
  tooltipAnchor: [13, -4]
};

const createIcon = iconUrl =>
  new Icon({
    ...iconBaseProps,
    iconUrl
  });

export const useCustomIcons = () => {
  const [icons, setIcons] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIcons({
        leafIcon: createIcon("marijuana.svg"),
        pharmacy: createIcon("cross.svg"),
        contract: createIcon("contract.svg"),
        vendingMachine: createIcon("vending-machine.svg"),
        question: createIcon("light-bulb.svg"),
        iconsReady: true
      });
    }
  }, []);

  const getMarkerIcon = markerType => {
    switch (markerType) {
      case "shop":
        return icons.leafIcon;
      case "pharmacy":
        return icons.pharmacy;
      case "signature":
        return icons.contract;
      case "vending machine":
        return icons.vendingMachine;
      case "other":
      default:
        return icons.question;
    }
  };

  return {
    ...icons,
    getMarkerIcon
  };
};
