import styled from "styled-components";
import { Box } from "rebass/styled-components";

export const Desktop = styled(Box)`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const Mobile = styled(Box)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
