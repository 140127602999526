import React from "react";
import Layout from "../components/Layout";
import MapWithData from "../components/Map/MapWithData";

const Home = () => {
  return (
    <Layout pageName="home">
      <MapWithData />
    </Layout>
  );
};

export default Home;
