import { Box, Flex, Text } from "rebass/styled-components";
import { Heading } from "rebass";
import Button from "../ui/Button";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";
import { Desktop, Mobile } from "../media";

export const Filters = props => {
  const { setFilter, filter } = props;
  const showAll = (
    <Button
      onClick={props.onClick}
      // ml={[5, 5, 3]}
      ml={[2]}
      mb={[3]}
      style={
        !props.filter
          ? { color: "#5F8575", background: "pink", borderColor: "pink" }
          : null
      }
    >
      <Text my={[-1]}>Wszystkie</Text>
    </Button>
  );

  return (
    <Box maxWidth={1000} mx={"auto"}>
      <Flex
        mt={[3]}
        justifyContent="flex-start"
        alignItems="center"
        flexWrap={"wrap"}
      >
        <Heading
          fontSize={[2, 2, 5]}
          mb={[0]}
          // mr={["auto"]}
          mt={[-2]}
        >
          FILTR
        </Heading>
        {/*<Mobile>{showAll}</Mobile>*/}
        {/*<Box width={[1, 0]} />*/}
        {typeMap.map(t => (
          <FilterButton setFilter={setFilter} filter={filter} {...t} />
        ))}
        {/*<Desktop>{showAll}</Desktop>*/}
        {showAll}
      </Flex>
    </Box>
  );
};

Filters.propTypes = {
  callbackfn: PropTypes.func,
  onClick: PropTypes.func,
  filter: PropTypes.any
};

const FilterButton = ({ setFilter, filter, ...t }) => {
  return (
    <Button
      onClick={() => setFilter(t.typeName)}
      ml={[2]}
      mb={[3]}
      style={{
        ...(t.typeName === filter
          ? { color: "white", background: "pink", borderColor: "pink" }
          : {})
      }}
    >
      <Flex alignItems="center" mx={[-2]}>
        <Box
          width={18}
          height={18}
          style={{
            backgroundImage: `url('/${t.svg}')`,
            backgroundSize: "contain"
          }}
        />

        {t.typeName === filter ? (
          <Text my={[-2]} ml={[2]}>
            {t.displayedName}
          </Text>
        ) : (
          <Desktop>
            <Text my={[-2]} ml={[2]}>
              {t.displayedName}
            </Text>
          </Desktop>
        )}
      </Flex>
    </Button>
  );
};

const typeMap = [
  {
    svg: "marijuana.svg",
    typeName: "shop",
    displayedName: "Sklep"
  },
  {
    svg: "cross.svg",
    typeName: "pharmacy",
    displayedName: "Apteka"
  },
  {
    svg: "vending-machine.svg",
    typeName: "vending machine",
    displayedName: "Automat"
  },
  {
    svg: "light-bulb.svg",
    typeName: "other",
    displayedName: "Inne"
  },
  {
    svg: "contract.svg",
    typeName: "signature",
    displayedName: "Punkt Zbierania Podpisów - Wolne Konopie"
  }
];
