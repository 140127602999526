import React, { useEffect, useState } from "react";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  GeoJSON,
  useLeaflet,
  withLeaflet
} from "react-leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";
import { Flex, Box, Text } from "rebass/styled-components";
import styled from "styled-components";
import Button from "../ui/Button";
import { getDistance } from "./utils/getDistance";
// import L from "leaflet";
import { PacmanLoader } from "react-spinners";
// import { Search } from "../Forms/Search";
import { SearchAutocomplete } from "./SearchAutocomplete";
import { ReactLeafletSearch } from "react-leaflet-search";

import { useCustomIcons } from "./useCustomIcons";
import { Logo } from "../Header";
import { Heading } from "rebass";
import * as PropTypes from "prop-types";
import { Filters } from "./Filters";
import { Desktop, Mobile } from "../media";
import { LatLng } from "leaflet";
const mapSettings = {
  center: {
    lat: 52.25,
    lng: 21
  },
  className: "map",
  zoom: 7,
  zoomControl: false,
  attributionControl: false,
  scrollWheelZoom: false
};
const sortMarkersByDistance = (currentPos, markers) => {
  var distances = [];

  markers.forEach(function(l) {
    var distance = getDistance(currentPos, l) / 1000;

    distances.push({ ...l, distance });
  });

  return distances.sort(function(a, b) {
    return a.distance - b.distance;
  });
};

// const SearchComponent = props => {
//   console.log("_______ props", props);
//   // return <SearchAutocomplete />;
//   // return withLeaflet(<ReactLeafletSearch />);
//   return <ReactLeafletSearch />;
// };

const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);

export const MyMap = ({
  markers = []
  // openedMarker
}) => {
  const [ready, setReady] = useState(false);
  const [openedMarker, setOpenedMarker] = useState({});
  const [updatedSettings, setUpdatedSettings] = useState({});
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showSearchOverlay, setSearchOverlay] = useState(false);
  const [markerIcon, setMarkerIcon] = useState(false);
  const { getMarkerIcon, iconsReady } = useCustomIcons();
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    // setOpenedMarker(markers[0]);
    setReady(true);
  }, []);

  const findClosest = () => {
    if (!navigator.geolocation) {
      error();
    }

    setSearchOverlay(true);

    navigator.geolocation.getCurrentPosition(
      p => {
        highlightClosestMarker({
          lng: p.coords.longitude,
          lat: p.coords.latitude
        });
      },
      error,
      {
        timeout: 5000
      }
    );
  };

  const initMarker = ref => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };

  function highlightClosestMarker({ lat, lng }) {
    const currentPos = {
      lng,
      lat
    };

    const closestMarkers = sortMarkersByDistance(currentPos, markers);

    setCurrentLocation(currentPos);

    setOpenedMarker(closestMarkers[0]);

    setUpdatedSettings({ center: currentPos });

    setSearchOverlay(false);
  }

  function error() {
    alert(
      "Nie udało nam się określić Twojej lokalizacji 🤖\nUżyj wyszukiwarki według nazw ulic!"
    );
    setSearchOverlay(false);
  }

  const enableScrollZoom = () => setUpdatedSettings({ scrollWheelZoom: true });

  const onMouseOut = () => setUpdatedSettings({ scrollWheelZoom: false });

  if (!iconsReady) {
    return <PacmanLoader type={"pacman"} color={"gray"} />;
  }

  const markersToDisplay = filter
    ? markers.filter(m => m.type === filter)
    : markers;
  return (
    <>
      {/*<Desktop>*/}
      <Box maxWidth={1000} mx={[-3, "auto"]}>
        <Flex
          justifyContent="space-between"
          py={3}
          flexGrow="1"
          flexWrap="wrap"
        >
          {/*<Desktop>*/}
          <Box
            mb={[3, 3, 0]}
            // mr={[-5, 0]}
          >
            <Logo />
          </Box>
          {/*</Desktop>*/}

          {/*<Flex alignItems="center">*/}
          {/*  <Heading>konopna mapa</Heading>*/}
          {/*</Flex>*/}
          <Button
            onClick={findClosest}
            // width={[1, 1, "auto"]}
            my={[3]}
            fontSize={[1, 1, 2]}
          >
            Najbliższy sklep
          </Button>
        </Flex>
      </Box>
      {/*</Desktop>*/}

      <Box height={["40vh", "40vh", "auto"]}>
        <Wrapper
          height={["40vh", "40vh", "auto"]}
          // p={24}
          onClick={enableScrollZoom}
        >
          <Map
            {...{ ...mapSettings, ...updatedSettings }}
            //onMouseOut={onMouseOut}
          >
            <ReactLeafletSearchComponent
              inputPlaceholder="Podaj nazwę miasta i ulicy"
              position="topleft"
              openSearchOnLoad={true}
              showMarker={false}
              closeResultsOnClick
              onChange={({ latLng, info, raw }) => {
                highlightClosestMarker(latLng);
              }}
              // customProvider={customProvider}
              providerOptions={{
                searchBounds: [new LatLng(54, 10), new LatLng(49, 24)]
              }}
            />
            <TileLayer
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              attribution={false}
              // url='http://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />

            {/*<MarkerClusterGroup>*/}
            {markersToDisplay.map(marker => (
              <Marker
                icon={getMarkerIcon(marker.type)}
                // icon={false}
                position={marker}
                onClick={() => setOpenedMarker(marker)}
                {...(marker.id === openedMarker.id ? { ref: initMarker } : {})}
              >
                <Popup>
                  <Box>
                    <Heading>{marker.name}</Heading>
                    {["street", "city", "telephone", "www", "email"].map(key =>
                      marker[key] === "?" ? null : <Text>{marker[key]}</Text>
                    )}
                  </Box>
                </Popup>
              </Marker>
            ))}
            {/*</MarkerClusterGroup>*/}
            {currentLocation && (
              <Marker position={currentLocation}>
                <Popup>{JSON.stringify(currentLocation)}</Popup>
              </Marker>
            )}
            <ZoomControl position="bottomright" />
          </Map>

          {iconsReady && (
            <Filters
              setFilter={setFilter}
              onClick={() => setFilter(null)}
              filter={filter}
            />
          )}

          <Flex height={"100px"} justifyContent="center" alignItems="center">
            <Heading>
              <strong>Kąt</strong>ent?
            </Heading>
          </Flex>

          {showSearchOverlay && (
            <MapOverlay>
              <Text>Szukam...</Text>
              <PacmanLoader type={"pacman"} color={"gray"} />
            </MapOverlay>
          )}
        </Wrapper>
      </Box>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  .map {
    //height: 100vh;
    //width: 100vw;
    height: calc(100vh - 110px - 100px);
    //width: calc(100vw);
    width: calc(100vw);
    //max-height: 60vh;
    padding: 0 24px;

    margin-left: -24px;
    margin-right: -24px;
  }
`;

const MapOverlay = styled(Flex)`
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 100000000;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default MyMap;
