import React from "react";
import { graphql, StaticQuery } from "gatsby";
import MyMap from "./MyMap";

export default props => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        #        allGoogleSheetShopsRow {
        allGoogleSheetShopsModRow {
          edges {
            node {
              id
              name
              lat
              lng
              type
              street
              telephone
              tags
              www
              city
              email
            }
          }
        }
      }
    `}
    render={data => {
      const markers =
        // data && data.allGoogleSheetShopsRow.edges.map(e => e.node);
        data && data.allGoogleSheetShopsModRow.edges.map(e => e.node);
      return <MyMap markers={markers} />;
    }}
  />
);
